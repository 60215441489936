$lawyer-consultation-primary: #00203f;
$lawyer-consultation-light-primary: #bf0a2f;
$lawyer-consultation-button: #bf0a2f;
$lawyer-consultation-progress: #9e9d9e;
$lawyer-consultation-secondary: #fdfdfd;
$lawyer-consultation-hover: #eef7ff;
$lawyer-consultation-link: #ff9a03;
$lawyer-consultation-phone-number-color: #bf0a2f;

:export {
    lcPrimary: $lawyer-consultation-primary;
    lcSecondary: $lawyer-consultation-secondary;
    lcLightPrimary: $lawyer-consultation-light-primary;
    lcHover: $lawyer-consultation-hover;
    lcLink: $lawyer-consultation-link;
    lcButton: $lawyer-consultation-button;
    lcProgress: $lawyer-consultation-progress;
}
