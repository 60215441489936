
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainBanner {
    // height: auto;
    color: $lawyer-consultation-primary;
    min-height: calc(100vh - 54px);
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url(/assets/bg14Opt.jpg);
    background-size: 370px;
    @media screen and (min-width: 760px) {
        background-size: 500px;
        // height: calc(100vh - 54px);
    }
    @media screen and (min-width: 901px) {
        background-size: 750px;
    }
}
.w1220 {
    width: 100%;
    max-width: inherit;
    @apply mx-4;

    @media screen and (min-width: 768px) {
        max-width: 1220px;
        @apply mx-0;
    }
}
.serviceBlocks {
    max-width: 920px;
}
.serviceBlock {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border: 1px solid $lawyer-consultation-primary;
    margin-left: auto;
    margin-right: auto;
    svg {
        width: 80%;
    }
    @media screen and (min-width: 768px) {
        width: 160px;
        height: 160px;
        svg {
            width: 80%;
        }
    }
}
.serviceBlockLnk {
    width: 80px;
    // flex: 0 0 50%;
    font-size: 12px;
    line-height: 1.2;
    margin: 0 10px 20px;
    @media screen and (min-width: 768px) {
        width: 200px;
        // flex: 0 0 25%;
        font-size: 16px;
        line-height: 1.4;
        margin: 0 15px 50px;
    }
    &:hover {
        .serviceBlock {
            background-color: $lawyer-consultation-primary;
        }
    }
}

.title {
    // font-family: Roboto;
    font-weight: 700;
    padding-top: 5rem;
    @media screen and (max-width: 767px) {
        padding-top: 0;
    }
}
.brief {
    @media screen and (max-width: 767px) {
        font-size: 12px;
        // height: 200px;
    }
}

a.accidentIcon:hover {
    svg {
        path {
            fill: #fff;
        }
    }
}
a.hurtIcon:hover {
    svg {
        path {
            fill: #fff;
        }
    }
}
a.hurtIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 70%;
        }
    }
}
a.productHarmIcon:hover {
    svg {
        path[fill] {
            fill: #fff;
        }
        path[stroke] {
            stroke: #fff;
        }
    }
}
a.socialSecurityDisabilityIcon:hover {
    svg {
        path[fill] {
            fill: #fff;
        }
        path[stroke] {
            stroke: #fff;
        }
    }
}
a.socialSecurityDisabilityIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 70%;
        }
    }
}
a.drugIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 50%;
        }
    }
}
