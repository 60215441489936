
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.hdrWrapper {
    background-color: $lawyer-consultation-primary;
    color: $lawyer-consultation-secondary;

    .banner {
        @include handleAllBreakpoints(
            "background-color",
            "bannerBackgroundColor",
            "var(--banner-background-color, #ccc)"
        );
        @include handleAllBreakpoints(
            "color",
            "bannerTextColor",
            "var(--banner-text-color, #000)"
        );
        // padding: 10px;
        text-align: center;

        display: grid;
        grid-template-rows: 0fr;
        transition: 250ms all ease;
        position: relative;
        overflow: hidden;
        padding-inline: 25px;

        .close-button {
            @apply absolute  border-0 outline-none cursor-pointer bg-transparent flex items-center justify-center;
            width: 20px;
            height: 20px;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            svg {
                width: 12px;
                height: 12px;
                fill: black;
                @include handleAllBreakpoints(
                    "fill",
                    "bannerTextColor",
                    "var(--banner-text-color, #000)"
                );
                @include lg {
                    width: 17px;
                    height: 17px;
                }
            }
            @include lg {
                right: 20px;
            }
        }
    }

    .banner.active {
        grid-template-rows: 1fr;
        padding-block: 10px;
    }

    .banner-inner {
        overflow: hidden;
    }
}
.hdr {
    width: 100%;
}
.slogan {
    svg {
        path {
            fill: #fff;
        }
    }
}
.hdrContainer {
    padding-left: 20px;
    padding-right: 20px;
}
.language {
    margin: 0 5px;
    img {
        aspect-ratio: 3/2.3;
        width: 20px;
    }
}
.logo {
    svg {
        width: 200px;
        @include md {
            width: 275px;
        }
    }
}
